import * as actions from "../actions"

export const initialLog = []

export const log = (state = initialLog, action) => {
  switch (action.type) {
    case actions.LOAD_LOG:
      return action.log
    default:
      return state
  }
}
