import {
  applyMiddleware,
  compose,
  createStore as createReduxStore,
} from "redux"
import thunk from "redux-thunk"
import rootReducer from "../reducers"
import { devToolsEnhancer } from "redux-devtools-extension/developmentOnly"
import _ from "lodash"

import { initialTheme } from "../reducers/theme"
import { initialSettings } from "../reducers/settings"
import { initialDamage } from "../reducers/damage"
import { initialLog } from "../reducers/log"
import { initialAddress } from "../reducers/address"
import { initialReports } from "../reducers/reports"

const initialState = {
  theme: initialTheme,
  settings: initialSettings,
  damage: initialDamage,
  log: initialLog,
  address: initialAddress,
  reports: initialReports,
}

const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state")
    if (serializedState === null) {
      return initialState
    }
    return JSON.parse(serializedState)
  } catch (_) {
    return initialState
  }
}

const saveState = state => {
  try {
    const serializedState = JSON.stringify(_.omit(state, "connection"))
    localStorage.setItem("state", serializedState)
  } catch (_) {
    // Error happen.
  }
}

const persistedState = loadState()

export const createStore = () => {
  const composedEnhancer = compose(
    applyMiddleware(thunk),
    devToolsEnhancer({
      name: "emergency-analyzer",
    })
  )

  return createReduxStore(rootReducer, persistedState, composedEnhancer)
}

const store = createStore()

store.subscribe(() => {
  saveState(store.getState())
})

export default store
