import * as actions from "../actions"

export const initialAddress = false

export const address = (state = initialAddress, action) => {
  switch (action.type) {
    case actions.SET_ADDRESS:
      return !state

    default:
      return state
  }
}
