import * as actions from "../actions"

export const initialTheme = false

export const theme = (state = initialTheme, action) => {
  switch (action.type) {
    case actions.TOGGLE_DARK_THEME:
      return !state

    default:
      return state
  }
}
