import * as actions from "../actions"

export const initialSettings = {
  name: null,
  feature: null,
  power: null,
  duration: null,
  scale: null,
  country: null,
  region: null,
  disaster: null,
  virus: "",
  period: 1,
  cont: 0.1,
  spaceObject: null,
  material: null,
  daypart: null,
  atmospheric: null,
  windSpeed: 0,
  airTemperature: 0,
  masks: 0,
  weight: 1,
}

export const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case actions.SET_DISASTER_NAME:
      return {
        ...state,
        name: action.name,
        power: null,
        feature: null,
        disaster: null,
        duration: null,
        scale: null,
        virus: "",
        period: 1,
        cont: 0.1,
        spaceObject: null,
        material: null,
        daypart: null,
        atmospheric: null,
        windSpeed: 0,
        airTemperature: 0,
        masks: 0,
        weight: 1,
      }
    case actions.SET_FEATURE:
      return {
        ...state,
        spaceObject: null,
        material: null,
        daypart: null,
        atmospheric: null,
        feature: action.feature,
      }
    case actions.SET_POWER:
      return { ...state, power: action.power }
    case actions.SET_DURATION:
      return { ...state, duration: action.duration }
    case actions.SET_SCALE:
      return { ...state, scale: action.scale }
    case actions.SET_COUNTRY:
      return {
        ...state,
        country: action.country,
        region: null,
      }
    case actions.SET_REGION:
      return { ...state, region: action.region }
    case actions.SET_DISASTER:
      return { ...state, disaster: action.disaster }
    case actions.SET_VIRUS:
      return { ...state, virus: action.virus }
    case actions.SET_PERIOD:
      return { ...state, period: action.period }
    case actions.SET_CONT:
      return { ...state, cont: action.cont }
    case actions.SET_SPACE_OBJECT:
      return { ...state, spaceObject: action.spaceObject }
    case actions.SET_MATERIAL:
      return { ...state, material: action.material }
    case actions.SET_DAYPART:
      return { ...state, daypart: action.daypart }
    case actions.SET_ATMOSPHERIC:
      return { ...state, atmospheric: action.atmospheric }
    case actions.SET_WIND_SPEED:
      return { ...state, windSpeed: action.windSpeed }
    case actions.SET_AIR_TEMPERATURE:
      return { ...state, airTemperature: action.airTemperature }
    case actions.SET_MASKS:
      return { ...state, masks: action.masks }
    case actions.SET_WEIGHT:
      return { ...state, weight: action.weight }
    default:
      return state
  }
}
