import * as actions from "../actions"

export const initialReports = []

export const reports = (state = initialReports, action) => {
  switch (action.type) {
    case actions.LOAD_REPORTS:
      return action.reports
    default:
      return state
  }
}
