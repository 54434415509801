import { combineReducers } from "redux"

import { theme } from "./theme"
import { settings } from "./settings"
import { log } from "./log"
import { damage } from "./damage"
import { address } from "./address"
import { reports } from "./reports"
import { connection } from "./connection"

export default combineReducers({
  theme,
  settings,
  log,
  damage,
  address,
  reports,
  connection,
})
