import * as actions from "../actions"

export const initialDamage = {
  id: 0,
  deaths: 0,
  victim: 0,
  epicenter: 0,
  casualty: 0,
  influence: 0,
  latitude: 0,
  longitude: 0,
  period: 0,
  startDeaths: 0,
  epicenterDeaths: 0,
  casualtyDeaths: 0,
  influenceDeaths: 0,
  epicenterVictim: 0,
  casualtyVictim: 0,
  influenceVictim: 0,
  status: false,
  victimOnRegion: 0,
  deathsOnRegion: 0,
}

export const damage = (state = initialDamage, action) => {
  switch (action.type) {
    case actions.SET_SIMULATION:
      return {
        ...state,
        ...action.data,
      }
    case actions.SET_DEFAULT_VALUES:
      return initialDamage
    default:
      return state
  }
}
