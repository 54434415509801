import * as types from "./types"
import axios from "axios"

export const toggleDarkTheme = () => ({
  type: types.TOGGLE_DARK_THEME,
})

export const loadLog = log => ({
  type: types.LOAD_LOG,
  log,
})

export const loadReports = reports => ({
  type: types.LOAD_REPORTS,
  reports,
})

export const logRequest = apiAddress => dispatch =>
  axios
    .get(`${apiAddress}/log`)
    .then(({ data }) => dispatch(loadLog(data)))
    .catch(error => console.log(error))

export const reportsRequest = apiAddress => dispatch =>
  axios
    .get(`${apiAddress}/simulation`)
    .then(({ data }) => dispatch(loadReports(data)))
    .catch(error => console.log(error))

export const setDisasterName = name => ({
  type: types.SET_DISASTER_NAME,
  name,
})

export const setFeature = feature => ({
  type: types.SET_FEATURE,
  feature,
})

export const setPower = power => ({
  type: types.SET_POWER,
  power,
})

export const setDuration = duration => ({
  type: types.SET_DURATION,
  duration,
})

export const setScale = scale => ({
  type: types.SET_SCALE,
  scale,
})

export const setCountry = country => ({
  type: types.SET_COUNTRY,
  country,
})

export const setRegion = region => ({
  type: types.SET_REGION,
  region,
})

export const setDisaster = disaster => ({
  type: types.SET_DISASTER,
  disaster,
})

export const setVirus = virus => ({
  type: types.SET_VIRUS,
  virus,
})

export const setPeriod = period => ({
  type: types.SET_PERIOD,
  period,
})

export const setCont = cont => ({
  type: types.SET_CONT,
  cont,
})

export const setSpaceObject = spaceObject => ({
  type: types.SET_SPACE_OBJECT,
  spaceObject,
})

export const setMaterial = material => ({
  type: types.SET_MATERIAL,
  material,
})

export const setDaypart = daypart => ({
  type: types.SET_DAYPART,
  daypart,
})

export const setAtmospheric = atmospheric => ({
  type: types.SET_ATMOSPHERIC,
  atmospheric,
})

export const setWindSpeed = windSpeed => ({
  type: types.SET_WIND_SPEED,
  windSpeed,
})

export const setAirTemperature = airTemperature => ({
  type: types.SET_AIR_TEMPERATURE,
  airTemperature,
})

export const setMasks = masks => ({
  type: types.SET_MASKS,
  masks,
})

export const setWeight = weight => ({
  type: types.SET_WEIGHT,
  weight,
})

export const setSimulation = data => ({
  type: types.SET_SIMULATION,
  data,
})

export const applySettings = (mode, prevention, apiAddress) => dispatch =>
  axios
    .post(`${apiAddress}/simulation`, {
      mode,
      prevention,
    })
    .then(({ data }) => dispatch(setSimulation(data)))
    .catch(error => {
      console.log(error)
    })

export const setAddress = () => ({
  type: types.SET_ADDRESS,
})

export const setBackUp = isUp => ({
  type: types.SET_BACK_UP,
  isUp,
})

export const setProxyUp = isUp => ({
  type: types.SET_PROXY_UP,
  isUp,
})

export const setDefaultValues = () => ({
  type: types.SET_DEFAULT_VALUES,
})
