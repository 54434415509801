export const TOGGLE_DARK_THEME = "TOGGLE_DARK_THEME"

export const LOAD_LOG = "LOAD_LOG"
export const LOAD_REPORTS = "LOAD_REPORTS"

export const SET_DISASTER_NAME = "SET_DISASTER_NAME"
export const SET_FEATURE = "SET_FEATURE"
export const SET_POWER = "SET_POWER"
export const SET_DURATION = "SET_DURATION"
export const SET_SCALE = "SET_SCALE"
export const SET_COUNTRY = "SET_COUNTRY"
export const SET_REGION = "SET_REGION"

export const SET_DISASTER = "SET_DISASTER"

export const SET_VIRUS = "SET_VIRUS"
export const SET_PERIOD = "SET_PERIOD"
export const SET_CONT = "SET_CONT"

export const SET_SPACE_OBJECT = "SET_SPACE_OBJECT"
export const SET_MATERIAL = "SET_MATERIAL"

export const SET_DAYPART = "SET_DAYPART"
export const SET_ATMOSPHERIC = "SET_ATMOSPHERIC"
export const SET_WIND_SPEED = "SET_WIND_SPEED"
export const SET_AIR_TEMPERATURE = "SET_AIR_TEMPERATURE"
export const SET_MASKS = "SET_MASKS"
export const SET_WEIGHT = "SET_WEIGHT"

export const SET_SIMULATION = "SET_SIMULATION"

export const SET_ADDRESS = "SET_ADDRESS"

export const SET_BACK_UP = "SET_BACK_UP"
export const SET_PROXY_UP = "SET_PROXY_UP"

export const SET_DEFAULT_VALUES = "SET_DEFAULT_VALUES"
