import * as actions from "../actions"

const initialConnection = {
  isBackUp: false,
  isProxyUp: false,
}

export const connection = (state = initialConnection, action) => {
  switch (action.type) {
    case actions.SET_BACK_UP:
      return {
        ...state,
        isBackUp: action.isUp,
      }
    case actions.SET_PROXY_UP:
      return {
        ...state,
        isProxyUp: action.isUp,
      }

    default:
      return state
  }
}
